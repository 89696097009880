// -----------------------------------------------------------------------------------------------------
// @ Common
// -----------------------------------------------------------------------------------------------------

// Header
%content-layout-header {
  display: flex;
  padding: 40px;

  @include treo-breakpoint('lt-md') {
    flex-direction: column;
  }

  @include treo-breakpoint('xs') {
    padding: 32px 24px;
  }

  .breadcrumb {
    margin-bottom: 8px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .left {
    align-self: center;
    margin-right: 24px;

    @include treo-breakpoint('lt-md') {
      align-self: flex-start;
      justify-self: center;
    }
  }

  .right {
    display: flex;
    align-items: center;
    align-self: center;
    margin-left: auto;
    white-space: nowrap;

    @include treo-breakpoint('lt-md') {
      align-self: flex-start;
      justify-self: center;
      margin-top: 24px;
      margin-left: 0;
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Content layout
// -----------------------------------------------------------------------------------------------------
.content-layout {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  max-width: 100%;
  min-width: 0;

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Basic - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-basic-normal-scroll {
    > .main {
      flex: 1 1 auto;
      padding: 40px;

      @include treo-breakpoint('xs') {
        padding: 24px;
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Basic - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-basic-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .main {
      flex: 1 1 auto;
      padding: 40px;

      @include treo-breakpoint('xs') {
        padding: 24px;
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Standard - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-standard-normal-scroll {
    > .header {
      @extend %content-layout-header;
    }

    > .main {
      flex: 1 1 auto;
      padding: 40px;

      @include treo-breakpoint('xs') {
        padding: 24px;
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Standard - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-standard-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .header {
      @extend %content-layout-header;
    }

    > .main {
      flex: 1 1 auto;
      padding: 40px;

      @include treo-breakpoint('xs') {
        padding: 24px;
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Standard - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-standard-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .header {
      @extend %content-layout-header;
    }

    > .main {
      flex: 1 1 auto;
      padding: 40px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      @include treo-breakpoint('xs') {
        padding: 24px;
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Tabs - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-tabs-normal-scroll {
    > .header {
      @extend %content-layout-header;
      padding-bottom: 24px;
    }

    > .main {
      flex: 1 1 auto;

      .mat-tab-group {
        .mat-tab-header {
          padding: 0 40px;

          @include treo-breakpoint('xs') {
            padding: 0 24px;
          }

          &.mat-tab-header-pagination-controls-enabled {
            padding: 0 32px;

            @include treo-breakpoint('xs') {
              padding: 0;
            }
          }

          .mat-tab-header-pagination {
            padding: 0;
            box-shadow: none;
          }

          .mat-tab-label {
            min-width: 128px;
          }
        }

        .mat-tab-body-wrapper {
          .mat-tab-body {
            .mat-tab-body-content {
              padding: 40px;

              @include treo-breakpoint('xs') {
                padding: 24px;
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Tabs - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-tabs-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .header {
      @extend %content-layout-header;
      padding-bottom: 24px;
    }

    > .main {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .mat-tab-group {
        .mat-tab-header {
          padding: 0 40px;

          @include treo-breakpoint('xs') {
            padding: 0 24px;
          }

          &.mat-tab-header-pagination-controls-enabled {
            padding: 0 32px;

            @include treo-breakpoint('xs') {
              padding: 0;
            }
          }

          .mat-tab-header-pagination {
            padding: 0;
            box-shadow: none;
          }

          .mat-tab-label {
            min-width: 128px;
          }
        }

        .mat-tab-body-wrapper {
          .mat-tab-body {
            .mat-tab-body-content {
              padding: 40px;

              @include treo-breakpoint('xs') {
                padding: 24px;
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Tabs - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-tabs-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .header {
      @extend %content-layout-header;
      padding-bottom: 24px;
    }

    > .main {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow: hidden;

      .mat-tab-group {
        overflow: hidden;

        .mat-tab-header {
          padding: 0 40px;

          @include treo-breakpoint('xs') {
            padding: 0 24px;
          }

          &.mat-tab-header-pagination-controls-enabled {
            padding: 0 32px;

            @include treo-breakpoint('xs') {
              padding: 0;
            }
          }

          .mat-tab-header-pagination {
            padding: 0;
            box-shadow: none;
          }

          .mat-tab-label {
            min-width: 128px;
          }
        }

        .mat-tab-body-wrapper {
          .mat-tab-body {
            .mat-tab-body-content {
              padding: 40px;
              -webkit-overflow-scrolling: touch;

              @include treo-breakpoint('xs') {
                padding: 24px;
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Tabs navigation - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-tabs-navigation-normal-scroll {
    > .header {
      @extend %content-layout-header;
      padding-bottom: 24px;
    }

    > .main {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      nav {
        margin: 0;
        padding: 0 40px;

        @include treo-breakpoint('xs') {
          padding: 0 24px;
        }

        .mat-tab-link {
          min-width: 128px;
        }
      }

      .main-inner {
        flex: 1 1 auto;
        padding: 40px;

        @include treo-breakpoint('xs') {
          padding: 24px;
        }

        > *:not(router-outlet) {
          display: block;
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Tabs navigation - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-tabs-navigation-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .header {
      @extend %content-layout-header;
      padding-bottom: 24px;
    }

    > .main {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      nav {
        margin: 0;
        padding: 0 40px;

        @include treo-breakpoint('xs') {
          padding: 0 24px;
        }

        .mat-tab-link {
          min-width: 128px;
        }
      }

      .main-inner {
        flex: 1 1 auto;
        padding: 40px;

        @include treo-breakpoint('xs') {
          padding: 24px;
        }

        > *:not(router-outlet) {
          display: block;
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Fullwidth - Tabs navigation - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.fullwidth-tabs-navigation-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .header {
      @extend %content-layout-header;
      padding-bottom: 24px;
    }

    > .main {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow: hidden;

      nav {
        margin: 0;
        padding: 0 40px;

        @include treo-breakpoint('xs') {
          padding: 0 24px;
        }

        .mat-tab-link {
          min-width: 128px;
        }
      }

      .main-inner {
        flex: 1 1 auto;
        padding: 40px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        @include treo-breakpoint('xs') {
          padding: 24px;
        }

        > *:not(router-outlet) {
          display: block;
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Basic - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-basic-normal-scroll {
    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Basic - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-basic-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Basic - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-basic-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Standard - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-standard-normal-scroll {
    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
        }

        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Standard - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-standard-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
        }

        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Standard - Drawer content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-standard-drawer-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
        }

        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Standard - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-standard-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .header {
          @extend %content-layout-header;
        }

        > .main {
          padding: 40px;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Tabs - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-tabs-normal-scroll {
    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Tabs - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-tabs-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Tabs - Drawer content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-tabs-drawer-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Tabs - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-tabs-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          flex: 1 1 auto;
          overflow: hidden;

          .mat-tab-group {
            height: 100%;

            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;
                  -webkit-overflow-scrolling: touch;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Tabs navigation - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-tabs-navigation-normal-scroll {
    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Tabs navigation - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-tabs-navigation-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Tabs navigation - Drawer content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-tabs-navigation-drawer-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar fullheight - Tabs navigation - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-fullheight-tabs-navigation-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          overflow: hidden;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar content - Standard - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-content-standard-normal-scroll {
    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar content - Standard - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-content-standard-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar content - Standard - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-content-standard-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .main {
          padding: 40px;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar content - Tabs - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-content-tabs-normal-scroll {
    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
                height: 56px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar content - Tabs - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-content-tabs-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
                height: 56px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar content - Tabs - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-content-tabs-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .main {
          flex: 1 1 auto;
          overflow: hidden;

          .mat-tab-group {
            height: 100%;

            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
                height: 56px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;
                  -webkit-overflow-scrolling: touch;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar content - Tabs navigation - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-content-tabs-navigation-normal-scroll {
    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
              height: 56px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar content - Tabs navigation - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-content-tabs-navigation-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
              height: 56px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Left sidebar content - Tabs navigation - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.left-sidebar-content-tabs-navigation-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          overflow: hidden;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
              height: 56px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Basic - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-basic-normal-scroll {
    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Basic - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-basic-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Basic - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-basic-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Standard - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-standard-normal-scroll {
    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
        }

        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Standard - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-standard-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
        }

        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Standard - Drawer content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-standard-drawer-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
        }

        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Standard - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-standard-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .header {
          @extend %content-layout-header;
        }

        > .main {
          padding: 40px;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Tabs - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-tabs-normal-scroll {
    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Tabs - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-tabs-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Tabs - Drawer content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-tabs-drawer-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Tabs - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-tabs-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          flex: 1 1 auto;
          overflow: hidden;

          .mat-tab-group {
            height: 100%;

            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;
                  -webkit-overflow-scrolling: touch;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Tabs navigation - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-tabs-navigation-normal-scroll {
    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Tabs navigation - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-tabs-navigation-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Tabs navigation - Drawer content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-tabs-navigation-drawer-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar fullheight - Tabs navigation - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-fullheight-tabs-navigation-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .header {
          @extend %content-layout-header;
          padding-bottom: 24px;
        }

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          overflow: hidden;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar content - Standard - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-content-standard-normal-scroll {
    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar content - Standard - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-content-standard-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          padding: 40px;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar content - Standard - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-content-standard-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .main {
          padding: 40px;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;

          @include treo-breakpoint('xs') {
            padding: 24px;
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar content - Tabs - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-content-tabs-normal-scroll {
    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
                height: 56px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar content - Tabs - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-content-tabs-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          flex: 1 1 auto;

          .mat-tab-group {
            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
                height: 56px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar content - Tabs - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-content-tabs-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .main {
          flex: 1 1 auto;
          overflow: hidden;

          .mat-tab-group {
            height: 100%;

            .mat-tab-header {
              padding: 0 40px;

              @include treo-breakpoint('xs') {
                padding: 0 24px;
              }

              &.mat-tab-header-pagination-controls-enabled {
                padding: 0 32px;

                @include treo-breakpoint('xs') {
                  padding: 0;
                }
              }

              .mat-tab-header-pagination {
                padding: 0;
                box-shadow: none;
              }

              .mat-tab-label {
                min-width: 128px;
                height: 56px;
              }
            }

            .mat-tab-body-wrapper {
              .mat-tab-body {
                .mat-tab-body-content {
                  padding: 40px;
                  -webkit-overflow-scrolling: touch;

                  @include treo-breakpoint('xs') {
                    padding: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar content - Tabs navigation - Normal scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-content-tabs-navigation-normal-scroll {
    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
              height: 56px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar content - Tabs navigation - Content scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-content-tabs-navigation-content-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      overflow: visible;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
              height: 56px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Right sidebar content - Tabs navigation - Inner scroll
  // -----------------------------------------------------------------------------------------------------
  &.right-sidebar-content-tabs-navigation-inner-scroll {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    > .header {
      @extend %content-layout-header;
    }

    > .mat-drawer-container {
      flex: 1 1 auto;
      height: 100%;

      .mat-drawer {
        min-width: 288px;
        max-width: 288px;
        width: 288px;
      }

      .mat-drawer-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .main {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          overflow: hidden;

          nav {
            margin: 0;
            padding: 0 40px;

            @include treo-breakpoint('xs') {
              padding: 0 24px;
            }

            .mat-tab-link {
              min-width: 128px;
              height: 56px;
            }
          }

          .main-inner {
            flex: 1 1 auto;
            padding: 40px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            @include treo-breakpoint('xs') {
              padding: 24px;
            }

            > *:not(router-outlet) {
              display: block;
            }
          }
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@include treo-theme {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $is-dark: map-get($theme, is-dark);

  .content-layout {
    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Basic - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-basic-normal-scroll {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Basic - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-basic-content-scroll {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Standard - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-standard-normal-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Standard - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-standard-content-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Standard - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-standard-inner-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Tabs - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-tabs-normal-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
      }

      > .main {
        .mat-tab-group {
          .mat-tab-header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Tabs - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-tabs-content-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
      }

      > .main {
        .mat-tab-group {
          .mat-tab-header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Tabs - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-tabs-inner-scroll {
      > .header {
        background: map-get($background, card);
      }

      > .main {
        .mat-tab-group {
          .mat-tab-header {
            background: map-get($background, card);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Tabs navigation - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-tabs-navigation-normal-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
      }

      > .main {
        nav {
          @if (not $is-dark) {
            background: map-get($background, card);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Tabs navigation - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-tabs-navigation-content-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
      }

      > .main {
        nav {
          @if (not $is-dark) {
            background: map-get($background, card);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Fullwidth - Tabs navigation - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.fullwidth-tabs-navigation-inner-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
      }

      > .main {
        nav {
          @if (not $is-dark) {
            background: map-get($background, card);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Basic - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-basic-normal-scroll {
      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Basic - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-basic-content-scroll {
      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Basic - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-basic-inner-scroll {
      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Standard - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-standard-normal-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
            border-bottom: 1px solid map-get($foreground, divider);
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Standard - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-standard-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
            border-bottom: 1px solid map-get($foreground, divider);
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Standard - Drawer content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-standard-drawer-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
            border-bottom: 1px solid map-get($foreground, divider);
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Standard - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-standard-inner-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
            border-bottom: 1px solid map-get($foreground, divider);
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Tabs - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-tabs-normal-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Tabs - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-tabs-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Tabs - Drawer content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-tabs-drawer-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Tabs - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-tabs-inner-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Tabs navigation - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-tabs-navigation-normal-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Tabs navigation - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-tabs-navigation-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Tabs navigation - Drawer content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-tabs-navigation-drawer-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar fullheight - Tabs navigation - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-fullheight-tabs-navigation-inner-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar content - Standard - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-content-standard-normal-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar content - Standard - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-content-standard-content-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar content - Standard - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-content-standard-inner-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar content - Tabs - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-content-tabs-normal-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar content - Tabs - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-content-tabs-content-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar content - Tabs - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-content-tabs-inner-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar content - Tabs navigation - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-content-tabs-navigation-normal-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar content - Tabs navigation - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-content-tabs-navigation-content-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Left sidebar content - Tabs navigation - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.left-sidebar-content-tabs-navigation-inner-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Basic - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-basic-normal-scroll {
      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Basic - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-basic-content-scroll {
      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Basic - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-basic-inner-scroll {
      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Standard - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-standard-normal-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
            border-bottom: 1px solid map-get($foreground, divider);
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Standard - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-standard-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
            border-bottom: 1px solid map-get($foreground, divider);
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Standard - Drawer content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-standard-drawer-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
            border-bottom: 1px solid map-get($foreground, divider);
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Standard - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-standard-inner-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
            border-bottom: 1px solid map-get($foreground, divider);
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Tabs - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-tabs-normal-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Tabs - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-tabs-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Tabs - Drawer content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-tabs-drawer-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Tabs - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-tabs-inner-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Tabs navigation - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-tabs-navigation-normal-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Tabs navigation - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-tabs-navigation-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Tabs navigation - Drawer content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-tabs-navigation-drawer-content-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar fullheight - Tabs navigation - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-fullheight-tabs-navigation-inner-scroll {
      > .mat-drawer-container {
        .mat-drawer-content {
          > .header {
            @if (not $is-dark) {
              background: map-get($background, card);
            }
          }

          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar content - Standard - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-content-standard-normal-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar content - Standard - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-content-standard-content-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar content - Standard - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-content-standard-inner-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar content - Tabs - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-content-tabs-normal-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar content - Tabs - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-content-tabs-content-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar content - Tabs - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-content-tabs-inner-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-group {
              .mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar content - Tabs navigation - Normal scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-content-tabs-navigation-normal-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar content - Tabs navigation - Content scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-content-tabs-navigation-content-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Right sidebar content - Tabs navigation - Inner scroll
    // -----------------------------------------------------------------------------------------------------
    &.right-sidebar-content-tabs-navigation-inner-scroll {
      > .header {
        @if (not $is-dark) {
          background: map-get($background, card);
        }
        border-bottom: 1px solid map-get($foreground, divider);
      }

      > .mat-drawer-container {
        .mat-drawer-content {
          > .main {
            .mat-tab-nav-bar {
              &.mat-tab-header {
                @if (not $is-dark) {
                  background: map-get($background, card);
                }
              }
            }
          }
        }

        .mat-drawer {
          @if ($is-dark) {
            background: treo-color('cool-gray', 900);
          }
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ You can use this file to import custom styles.
//
// @ Styles from this file will override anything from 'vendors.scss' file allowing customizations and
//  modifications of third party libraries.
// -----------------------------------------------------------------------------------------------------
@import '~material-icons/iconfont/material-icons.css';

@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import "../../node_modules/placeholder-loading/dist/css/placeholder-loading.min.css";

//RRS
@import "~mapbox-gl/dist/mapbox-gl.css";

.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
  transform: none !important;
}

// Global TSUI component styles
@import './../../projects/tsui/src/assets/styles/global/ts-snackbar';
@import './../../projects/tsui/src/assets/styles/global/ts-select-search-input';

//rrs for snackbar
.mat-simple-snackbar-action {
  button {
    background: #ff6700 !important;
  }
}

.display-none {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.mat-dialog-container {
  padding: 0 !important;
}

.locked {
  color: #97a6ba !important;
}

.height-100p {
  height: 100%;
}

// Force line-break inside mat-tooltip.
.mat-tooltip {
  white-space: pre-wrap;
}

.error-snackbar {
  background: #ed4848 !important;
  color: #fff !important;
}

.success-snackbar {
  background: #31C48D !important;
  color: #fff !important;
}

.ethan-dialog .mat-dialog-container {
  border-radius: 16px;
  background-color: #1E1E1E !important;
}
.ethan-dialog *::-webkit-scrollbar {
  display: none;
}

.ethan-dialog{
  transition: height 0.5s, width 0.5s;
}

.ph-item {
  width: 100%;
  border: none;
}

.mat-calendar-previous-button {
  max-width: unset !important;
  max-height: unset !important;
}

.mat-calendar-next-button {
  max-width: unset !important;
  max-height: unset !important;
}

.ag-header-group-cell-label, .ag-header-cell-label {
  display: flex;
  justify-content: space-between;
}


.ag-cell {
  border-bottom: none !important;
  border-top: none !important;
}

.ag-cell-focus {
  box-shadow: inset 0 0 0 0.5px var(--ag-active-color);
}

.ag-pinned-left-cols-container .ag-row {
  border-right: none !important;
}

.ag-center-cols-container {
    min-width: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

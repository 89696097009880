// -----------------------------------------------------------------------------------------------------
// @ Manually forked from TailwindCSS preflight.css
// -----------------------------------------------------------------------------------------------------

/**
 * Manually forked from SUIT CSS Base: https://github.com/suitcss/base
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

/**
 * 1. Prevent padding and border from affecting element width
 * https://goo.gl/pYtbK7
 * 2. Change the default font family in all browsers (opinionated)
 */

html {
  box-sizing: border-box; /* 1 */
  font-family: sans-serif; /* 2 */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Removes the default spacing and border for appropriate elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
  margin: 0;
}

button {
  background: transparent;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Tailwind custom reset styles
 */

/**
 * 1. Use the system font stack as a sane default.
 * 2. Use Tailwind's default "normal" line-height so the user isn't forced
 * to override it to ensure consistency even when using the default theme.
 */

html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; /* 1 */
  line-height: 1.5; /* 2 */
}

/**
 * Allow adding a border to an element by just adding a border-width.
 *
 * By default, the way the browser specifies that an element should have no
 * border is by setting it's border-style to `none` in the user-agent
 * stylesheet.
 *
 * In order to easily add borders to elements by just setting the `border-width`
 * property, we change the default border-style for all elements to `solid`, and
 * use border-width to hide them instead. This way our `border` utilities only
 * need to set the `border-width` property instead of the entire `border`
 * shorthand, making our border utilities much more straightforward to compose.
 *
 * https://github.com/tailwindcss/tailwindcss/pull/116
 */
*,
*::before,
*::after {
  border-width: 0;
  border-style: solid;
  // Default border color is defined in 'styles/base/_colors.scss' file for convenience
}

/**
 * Undo the `border-style: none` reset that Normalize applies to images so that
 * our `border-{width}` utilities have the expected effect.
 *
 * The Normalize reset is unnecessary for us since we default the border-width
 * to 0 on all elements.
 *
 * https://github.com/tailwindcss/tailwindcss/issues/362
 */
img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  color: inherit;
  //opacity: 0.5;
}

button,
[role='button'] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/**
 * Reset links to optimize for opt-in styling instead of
 * opt-out.
 */

a {
  color: inherit;
  text-decoration: inherit;
}

/**
 * Reset form element properties that are easy to forget to
 * style explicitly so you don't inadvertently introduce
 * styles that deviate from your design system. These styles
 * supplement a partial reset that is already applied by
 * normalize.css.
 */

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

/**
 * Use the configured 'mono' font family for elements that
 * are expected to be rendered with a monospace font, falling
 * back to the system monospace stack if there is no configured
 * 'mono' font family.
 */

pre,
code,
kbd,
samp {
  font-family: $treo-font-mono;
}

/**
 * Make replaced elements `display: block` by default as that's
 * the behavior you want almost all of the time. Inspired by
 * CSS Remedy, with `svg` added as well.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

/**
 * Constrain images and videos to the parent width and preserve
 * their instrinsic aspect ratio.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
video {
  max-width: 100%;
  height: auto;
}

// -----------------------------------------------------------------------------------------------------
// @ Treo custom reset styles
// -----------------------------------------------------------------------------------------------------

* {
  // Text rendering
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;

  // Disable default focus outline
  &:focus {
    outline: none !important;
  }

  // Enable focus outline only on keyboard focused buttons
  button.cdk-focused.cdk-keyboard-focused {
    outline: 1px dotted !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}

html,
body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  min-height: 100%;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

hr {
  margin: 32px 0;
  border-bottom-width: 1px;
}

img {
  width: 100%;
  vertical-align: top;
}

// Fix: Disabled placeholder color is too faded on Safari
input[disabled] {
  opacity: 1;
  -webkit-text-fill-color: currentColor;
}

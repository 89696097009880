/**
 * This file is being used by injecting custom TailwindCSS variants.
 *
 * These variants are different because these will not generate any
 * CSS rules, but they will generate SCSS variables from your Tailwind
 * config file.
 *
 * The generated output will be used by Treo.
 * Do NOT modify or use this file to generate your own variants.
 */

$treo-elevations: (
  'sm': '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  'DEFAULT': '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  'md': '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  'lg': '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  'xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  'inner': 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
  'none': 'none',
  'solid': '0 0 0 2px currentColor',
  'default': '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  'xs': '0 0 0 1px rgba(0, 0, 0, 0.05)',
) !default;

$treo-colors: (
  'white': (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    contrast: (
      50: #252f3f,
      100: #252f3f,
      200: #252f3f,
      300: #252f3f,
      400: #252f3f,
      500: #252f3f,
      600: #252f3f,
      700: #252f3f,
      800: #252f3f,
      900: #252f3f,
    ),
  ),
  'black': (
    50: #f5f5f5,
    100: #f4f5f7,
    200: #e6e6e6,
    300: #cccccc,
    350: #8e8e8e,
    400: #858585,
    500: #6b7280,
    600: #4b5563,
    700: #262626,
    800: #1e1e1e,
    850: #1e1e1e,
    900: #000000,
    contrast: (
      50: #ffffff,
      100: #ffffff,
      200: #ffffff,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
    ),
  ),
  'gray': (
    50: #f9fafb,
    100: #f4f5f7,
    150: #f2f2f2,
    200: #e5e7eb,
    300: #d2d6dc,
    400: #9fa6b2,
    500: #6b7280,
    600: #4b5563,
    700: #374151,
    800: #252f3f,
    900: #161e2e,
    contrast: (
      50: #161e2e,
      100: #161e2e,
      200: #161e2e,
      300: #161e2e,
      400: #161e2e,
      500: #161e2e,
      600: #f9fafb,
      700: #f9fafb,
      800: #f9fafb,
      900: #f9fafb,
    ),
  ),
  'cool-gray': (
    50: #fbfdfe,
    100: #f1f5f9,
    200: #e2e8f0,
    300: #cfd8e3,
    400: #97a6ba,
    500: #64748b,
    600: #475569,
    700: #364152,
    800: #27303f,
    900: #1a202e,
    contrast: (
      50: #1a202e,
      100: #1a202e,
      200: #1a202e,
      300: #1a202e,
      400: #1a202e,
      500: #1a202e,
      600: #fbfdfe,
      700: #fbfdfe,
      800: #fbfdfe,
      900: #fbfdfe,
    ),
  ),
  'red': (
    50: #fdf2f2,
    100: #fde8e8,
    200: #fbd5d5,
    300: #f8b4b4,
    400: #f98080,
    500: #f05252,
    600: #e02424,
    700: #c81e1e,
    800: #d82d34,
    900: #771d1d,
    contrast: (
      50: #771d1d,
      100: #771d1d,
      200: #771d1d,
      300: #771d1d,
      400: #771d1d,
      500: #771d1d,
      600: #fdf2f2,
      700: #fdf2f2,
      800: #fdf2f2,
      900: #fdf2f2,
    ),
  ),
  'orange': (
    50: #fff8f1,
    100: #feecdc,
    200: #fcd9bd,
    300: #fdba8c,
    400: #ff8a4c,
    500: #ff5a1f,
    600: #ff6700,
    700: #b43403,
    800: #8a2c0d,
    900: #771d1d,
    contrast: (
      50: #771d1d,
      100: #771d1d,
      200: #771d1d,
      300: #771d1d,
      400: #771d1d,
      500: #771d1d,
      600: #fff8f1,
      700: #fff8f1,
      800: #fff8f1,
      900: #fff8f1,
    ),
  ),
  'yellow': (
    50: #fff1e1,
    100: #ffd5a9,
    200: #fce96a,
    300: #faca15,
    400: #e3a008,
    500: #c27803,
    600: #9f580a,
    700: #904e00,
    800: #723b13,
    900: #633112,
    contrast: (
      50: #633112,
      100: #633112,
      200: #633112,
      300: #633112,
      400: #633112,
      500: #633112,
      600: #fff1e1,
      700: #fff1e1,
      800: #fff1e1,
      900: #fff1e1,
    ),
  ),
  'green': (
    50: #f3faf7,
    100: #def7ec,
    200: #bcf0da,
    300: #84e1bc,
    400: #31c48d,
    500: #0e9f6e,
    600: #057a55,
    700: #046c4e,
    800: #03543f,
    900: #014737,
    contrast: (
      50: #014737,
      100: #014737,
      200: #014737,
      300: #014737,
      400: #014737,
      500: #f3faf7,
      600: #f3faf7,
      700: #f3faf7,
      800: #f3faf7,
      900: #f3faf7,
    ),
  ),
  'teal': (
    50: #edfafa,
    100: #d5f5f6,
    200: #afecef,
    300: #7edce2,
    400: #16bdca,
    500: #0694a2,
    600: #047481,
    700: #036672,
    800: #05505c,
    900: #014451,
    contrast: (
      50: #014451,
      100: #014451,
      200: #014451,
      300: #014451,
      400: #014451,
      500: #edfafa,
      600: #edfafa,
      700: #edfafa,
      800: #edfafa,
      900: #edfafa,
    ),
  ),
  'blue': (
    50: #ebf5ff,
    100: #c3e0fb,
    200: #c3ddfd,
    300: #a4cafe,
    400: #76a9fa,
    500: #3f83f8,
    600: #1c64f2,
    700: #0067a2,
    800: #1e429f,
    900: #233876,
    contrast: (
      50: #233876,
      100: #233876,
      200: #233876,
      300: #233876,
      400: #233876,
      500: #ebf5ff,
      600: #ebf5ff,
      700: #ebf5ff,
      800: #ebf5ff,
      900: #ebf5ff,
    ),
  ),
  'indigo': (
    50: #f0f5ff,
    100: #e5edff,
    200: #cddbfe,
    300: #b4c6fc,
    400: #8da2fb,
    500: #6875f5,
    600: #5850ec,
    700: #5145cd,
    800: #42389d,
    900: #362f78,
    contrast: (
      50: #362f78,
      100: #362f78,
      200: #362f78,
      300: #362f78,
      400: #362f78,
      500: #f0f5ff,
      600: #f0f5ff,
      700: #f0f5ff,
      800: #f0f5ff,
      900: #f0f5ff,
    ),
  ),
  'purple': (
    50: #f6f5ff,
    100: #edebfe,
    200: #dcd7fe,
    300: #cabffd,
    400: #ac94fa,
    500: #9061f9,
    600: #7e3af2,
    700: #6c2bd9,
    800: #5521b5,
    900: #4a1d96,
    contrast: (
      50: #4a1d96,
      100: #4a1d96,
      200: #4a1d96,
      300: #4a1d96,
      400: #4a1d96,
      500: #f6f5ff,
      600: #f6f5ff,
      700: #f6f5ff,
      800: #f6f5ff,
      900: #f6f5ff,
    ),
  ),
  'pink': (
    50: #fdf2f8,
    100: #fce8f3,
    200: #fad1e8,
    300: #f8b4d9,
    400: #f17eb8,
    500: #e74694,
    600: #d61f69,
    700: #bf125d,
    800: #99154b,
    900: #751a3d,
    contrast: (
      50: #751a3d,
      100: #751a3d,
      200: #751a3d,
      300: #751a3d,
      400: #751a3d,
      500: #fdf2f8,
      600: #fdf2f8,
      700: #fdf2f8,
      800: #fdf2f8,
      900: #fdf2f8,
    ),
  ),
) !default;

$treo-font-sans: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$treo-font-serif: ui-serif, Georgia, Cambria, 'Times New Roman', Times, serif !default;

$treo-font-mono: 'IBM Plex Mono', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;

$treo-breakpoints: (
  xs: '(min-width: 0) and (max-width: 599px)',
  sm: '(min-width: 600px) and (max-width: 959px)',
  md: '(min-width: 960px) and (max-width: 1279px)',
  lg: '(min-width: 1280px) and (max-width: 1439px)',
  xl: '(min-width: 1440px)',
  lt-md: '(max-width: 959px)',
  lt-lg: '(max-width: 1279px)',
  lt-xlg: '(max-width: 1326px)',
  lt-xl: '(max-width: 1439px)',
  gt-xs: '(min-width: 600px)',
  gt-sm: '(min-width: 960px)',
  gt-md: '(min-width: 1280px)',
) !default;

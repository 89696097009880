// -----------------------------------------------------------------------------------------------------
// @ Generate and apply base theme colors
// -----------------------------------------------------------------------------------------------------
@include treo-theme {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  // Base color and background
  & {
    color: map-get($foreground, text);
    background-color: map-get($background, background);
  }

  // Base border color for all elements
  *,
  *::before,
  *::after {
    border-color: map-get($foreground, divider);
  }

  // Force the disabled colors on disabled elements
  [disabled] {
    * {
      color: map-get($foreground, disabled) !important;
    }
  }

  // TODO: check if this is needed
  // .mat-icon {
  //   @if ($is-dark) {
  //     color: map-get($foreground, secondary-text);
  //   } @else {
  //     color: map-get($foreground, icon);
  //   }
  // }

  .text-card {
    color: map-get($background, card);
  }

  .text-default {
    color: map-get($foreground, text);
  }

  .text-secondary {
    color: map-get($foreground, secondary-text);
  }

  .text-hint {
    color: map-get($foreground, hint-text);
  }

  .text-disabled {
    color: map-get($foreground, disabled-text);
  }

  .divider {
    color: map-get($foreground, divider);
  }

  // Background colors
  .bg-default {
    background-color: map-get($background, background);
  }

  .bg-dialog,
  .bg-card {
    background-color: map-get($background, card);
  }

  .bg-hover {
    background-color: map-get($background, hover);
  }

  // Dark - light variants
  @if ($is-dark) {
    &.dark\:text-normal,
    .dark\:text-normal {
      color: map-get($foreground, text);
    }

    &.dark\:text-secondary,
    .dark\:text-secondary {
      color: map-get($foreground, secondary-text);
    }

    &.dark\:text-hint,
    .dark\:text-hint {
      color: map-get($foreground, hint-text);
    }

    &.dark\:text-disabled,
    .dark\:text-disabled {
      color: map-get($foreground, disabled-text);
    }

    &.dark\:text-divider,
    .dark\:text-divider {
      color: map-get($foreground, divider);
    }

    &.dark\:bg-default,
    .dark\:bg-default {
      background-color: map-get($background, background);
    }

    &.dark\:bg-dialog,
    .dark\:bg-dialog,
    &.dark\:bg-card,
    .dark\:bg-card {
      background-color: map-get($background, card);
    }

    &.dark\:bg-hover,
    .dark\:bg-hover {
      background-color: map-get($background, hover);
    }
  } @else {
    &.light\:text-normal,
    .light\:text-normal {
      color: map-get($foreground, text);
    }

    &.light\:text-secondary,
    .light\:text-secondary {
      color: map-get($foreground, secondary-text);
    }

    &.light\:text-hint,
    .light\:text-hint {
      color: map-get($foreground, hint-text);
    }

    &.light\:text-disabled,
    .light\:text-disabled {
      color: map-get($foreground, disabled-text);
    }

    &.light\:text-divider,
    .light\:text-divider {
      color: map-get($foreground, divider);
    }

    &.light\:bg-default,
    .light\:bg-default {
      background-color: map-get($background, background);
    }

    &.light\:bg-dialog,
    .light\:bg-dialog,
    &.light\:bg-card,
    .light\:bg-card {
      background-color: map-get($background, card);
    }

    &.light\:bg-hover,
    .light\:bg-hover {
      background-color: map-get($background, hover);
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Quill editor style overrides
// -----------------------------------------------------------------------------------------------------

.ql-toolbar {
  border-radius: 6px 6px 0 0;
  padding: 0 !important;

  .ql-formats {
    margin: 11px 8px !important;
  }

  .ql-picker {
    &.ql-expanded {
      .ql-picker-options {
        z-index: 10 !important;
      }
    }
  }
}

.ql-container {
  overflow: hidden;
  border-radius: 0 0 6px 6px;
  @include treo-elevation('sm');

  .ql-editor {
    min-height: 160px;
    max-height: 160px;
    height: 160px;
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@include treo-theme {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $is-dark: map-get($theme, is-dark);

  .ql-toolbar {
    @if ($is-dark) {
      background-color: rgba(0, 0, 0, 0.05);
      border-color: treo-color('cool-gray', 500);
    } @else {
      background: treo-color('cool-gray', 100);
      border-color: treo-color('cool-gray', 300);
    }

    .ql-picker {
      &.ql-expanded {
        .ql-picker-label {
          @if ($is-dark) {
            border-color: treo-color('cool-gray', 500);
          } @else {
            border-color: treo-color('cool-gray', 300);
          }
        }

        .ql-picker-options {
          @if ($is-dark) {
            border-color: treo-color('cool-gray', 500);
          } @else {
            border-color: treo-color('cool-gray', 300);
          }
          background: map-get($background, card);
        }
      }

      .ql-picker-label {
        color: map-get($foreground, text);
      }

      .ql-picker-options {
        .ql-picker-item {
          color: map-get($foreground, text);
        }
      }
    }

    .ql-stroke,
    .ql-stroke-mitter {
      stroke: map-get($foreground, icon);
    }

    .ql-fill {
      fill: map-get($foreground, icon);
    }

    button:hover,
    button:focus,
    button.ql-active,
    .ql-picker-label:hover,
    .ql-picker-label.ql-active,
    .ql-picker-item:hover,
    .ql-picker-item.ql-selected {
      color: map-get($primary, default) !important;

      .ql-stroke,
      .ql-stroke-mitter {
        stroke: map-get($primary, default) !important;
      }

      .ql-fill {
        fill: map-get($primary, default) !important;
      }
    }
  }

  .ql-container {
    @if ($is-dark) {
      border-color: treo-color('cool-gray', 500);
    } @else {
      border-color: treo-color('cool-gray', 300);
    }

    .ql-editor {
      @if ($is-dark) {
        background-color: rgba(0, 0, 0, 0.05);
      } @else {
        background-color: treo-color('cool-gray', 50);
      }

      &:focus {
        background-color: map-get($background, card);
      }

      &.ql-blank::before {
        color: map-get($foreground, hint-text);
      }
    }
  }
}

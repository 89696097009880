.ts-snackbar {
  &__info {
    @apply bg-blue-50 border border-blue-400 text-blue-900 #{!important};
  }

  &__error {
    @apply bg-red-50 border border-red-400 text-red-900 #{!important};
  }

  &__success {
    @apply bg-green-50 border border-green-400 text-green-900 #{!important};
  }

  &__warning {
    @apply bg-yellow-50 border border-yellow-400 text-yellow-900 #{!important};
  }
}

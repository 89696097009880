// -----------------------------------------------------------------------------------------------------
// @ Highlight.js color scheme overrides
// -----------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------
// @ Theming
// -----------------------------------------------------------------------------------------------------
@include treo-theme {
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);

  // DARK COLOR SCHEME
  @if ($is-dark) {
    code,
    pre {
      .hljs-built_in,
      .hljs-selector-tag,
      .hljs-section,
      .hljs-link {
        color: #8be9fd;
      }

      .hljs-keyword {
        color: #ff79c6;
      }

      .hljs,
      .hljs-subst {
        color: #f8f8f2;
      }

      .hljs-title {
        color: #50fa7b;
      }

      .hljs-meta,
      .hljs-type,
      .hljs-symbol,
      .hljs-bullet,
      .hljs-addition,
      .hljs-variable,
      .hljs-template-tag,
      .hljs-template-variable {
        color: #f1fa8c;
      }

      .hljs-name {
        color: #80deea;
      }

      .hljs-attr {
        color: #e1bee7;
      }

      .hljs-string {
        color: #a5d6a7;
      }

      .hljs-comment,
      .hljs-quote,
      .hljs-deletion {
        color: #6272a4;
      }

      .hljs-keyword,
      .hljs-selector-tag,
      .hljs-literal,
      .hljs-title,
      .hljs-section,
      .hljs-doctag,
      .hljs-type,
      .hljs-name,
      .hljs-strong {
        font-weight: 700;
      }

      .hljs-literal,
      .hljs-number {
        color: #bd93f9;
      }

      .hljs-emphasis {
        font-style: italic;
      }
    }
  }
  // LIGHT COLOR SCHEME
  @else {
    code[class*='language-'],
    pre[class*='language-'] {
      .hljs-comment,
      .hljs-quote {
        color: #a0a1a7;
        font-style: italic;
      }

      .hljs-doctag,
      .hljs-keyword,
      .hljs-formula {
        color: #a626a4;
      }

      .hljs-name {
        color: #7986cb;
      }

      .hljs-tag {
        color: #b9bbd2;
      }

      .hljs-section,
      .hljs-selector-tag,
      .hljs-deletion,
      .hljs-subst {
        color: #e45649;
      }

      .hljs-literal {
        color: #0184bb;
      }

      .hljs-string,
      .hljs-regexp,
      .hljs-addition,
      .hljs-attribute,
      .hljs-meta-string {
        color: #50a14f;
      }

      .hljs-built_in,
      .hljs-class .hljs-title {
        color: #c18401;
      }

      .hljs-attr,
      .hljs-variable,
      .hljs-template-variable,
      .hljs-type,
      .hljs-selector-class,
      .hljs-selector-attr,
      .hljs-selector-pseudo,
      .hljs-number {
        color: #ba68c8;
      }

      .hljs-symbol,
      .hljs-bullet,
      .hljs-link,
      .hljs-meta,
      .hljs-selector-id,
      .hljs-title {
        color: #4078f2;
      }

      .hljs-emphasis {
        font-style: italic;
      }

      .hljs-strong {
        font-weight: 700;
      }

      .hljs-link {
        text-decoration: underline;
      }
    }
  }
}

// -----------------------------------------------------------------------------------------------------
// @ Mixins
// -----------------------------------------------------------------------------------------------------

///
/// Wrap the mixin content with the given media breakpoint.
/// If breakpoint name does not exist on the breakpoints list,
/// apply the given name as a media rule.
///
/// @access public
/// @param {String} $breakpoint - Name of the breakpoint or a media rule
///
@mixin treo-breakpoint($breakpoint) {
  $mediaQuery: map-get($treo-breakpoints, $breakpoint);

  @if ($mediaQuery != null) {
    @media #{$mediaQuery} {
      @content;
    }
  } @else {
    @media #{$breakpoint} {
      @content;
    }
  }
}

@mixin downscale-breakpoint() {
  @media screen and (max-width: 1300px) {
    @content;
  }
}

@mixin responsive-height($offset) {
  height: 100%;
  max-height: calc(100vh - #{$offset});

  @include downscale-breakpoint {
    max-height: calc(111vh - #{$offset});
  }
}
